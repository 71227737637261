'use client';

import { useState } from 'react';

import { Button, Modal, Stack } from '@carbon/react';
import { useInterval } from 'usehooks-ts';

export const DeployRefreshManager: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [interval, setInterval] = useState<number | null>(60000);

  useInterval(async () => {
    const response = await fetch('/api');

    if (response.ok) {
      const { deploymentId } = await response.json();

      if (process.env.NEXT_PUBLIC_DEPLOYMENT_ID !== deploymentId) {
        setInterval(null);
        // eslint-disable-next-line no-console
        console.info('New application version available');
        // setOpen(true);
      }
    }
  }, interval);

  // check every 30 seconds
  return (
    <>
      <span hidden>{process.env.NEXT_PUBLIC_DEPLOYMENT_ID}</span>
      <Modal
        open={open}
        passiveModal
        preventCloseOnClickOutside
        modalHeading="New application version available"
        onRequestClose={() => {
          setOpen(false);
        }}
      >
        <Stack gap={6}>
          <p>
            A new version of the application is available. Click the button
            below to reload the application.
          </p>
          <p>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload application
            </Button>
          </p>
        </Stack>
      </Modal>
    </>
  );
};
